<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-icon="mdi-vector-link" page-title="Broken Links">
    <g-list-page
        elevation="elevation-0"
        :columns="columns"
        :headers="headers"
        :show-default-action-buttons="false"
        :action-buttons="actionButton"
        stateendpoint="brokenLink.brokenLinks"
        sort="orderId"
        @loadData="loadData"
        @destroyItem="destroyItem"
        @restoreItem="restoreItem"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="2" sm="6">
            <add-button
                text="Clear All Links"
                icon="mdi-recycle-variant"
                @onButtonClick="cacheClearHandler()"
            />
          </v-col>
        </v-row>
      </template>

    </g-list-page>
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div>
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import GCard from "../components/GCard";
import GListPage from "../components/list/GListPage";
import ListMixins from "../components/mixins/list/List";
import PageOverlay from "../components/PageOverlay";
import GPSearch from "../components/GPSearch";
import AddButton from "../components/buttons/AddButton";
import ConfirmPopup from "../components/popups/ConfirmPopup";

export default {
  name: 'BrokenLinks',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Broken Links | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  mixins: [ListMixins],
  components: {
    ConfirmPopup,
    AddButton,
    GPSearch,
    PageOverlay,
    GListPage,
    GCard,
  },
  data() {
    return {
      headers: [
        {
          text: 'Order ID',
          value: 'orderId'
        },
        {
          text: 'Site URL',
          value: 'siteUrl'
        },
        {
          text: 'Live Link',
          value: 'liveLink'
        },
        {
          text: 'Payment Status',
          value: 'status'
        },
        {
          text: 'Server Status',
          value: 'status_code'
        },
        {
          text: 'Reason',
          value: 'reason'
        },
        {
          text: 'Created At',
          value: 'created_at'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      columns: [
        {
          name: "created_at",
          callback: "getDate"
        },
      ],
      actions: {
        load: 'brokenLink/load',
        restore: 'brokenLink/update',
        destroy: 'brokenLink/destroy',
        cacheClear: 'brokenLink/clear'
      },
      actionButton: [
        {
          category: 'restoreBrokenLink',
          icon: 'mdi-backup-restore',
          color: '',
          text: 'Restore Link',
          clickHandler: 'restoreItem'
        },
        {
          category: 'destroyBrokenLink',
          icon: 'mdi-delete',
          color: 'error',
          text: 'Fixed Manually',
          clickHandler: 'destroyItem'
        },
      ],
    }
  },
}
</script>
